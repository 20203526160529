.parent {

    .swiper-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        padding: 0;
        width: max-content;
    }

    .swiper-pagination {
        position: absolute !important;
        margin-bottom: 46px;
        margin-left: 48px;

        .swiper-pagination-bullet {
            width: 26px;
            height: 5px;
            border-radius: 4px;
            opacity: 1;
            background-color: #C1C1C1 !important;
        }

        .swiper-pagination-bullet-active {
            background-color: #EC1C24 !important;
        }
    }

    .hero-section {
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 637px;

        @media screen and (min-width: 768px) and (max-width: 1028px) {
            height: 337px;
        }

        @media screen and (min-width: 1200px) and (max-width: 1365px) {
            height: 507px;
        }

        @media screen and (min-width: 1640px) {
            height: 707px;
        }

        @media screen and (min-width: 1920px) {
            height: 807px;
        }

        @media screen and (min-width: 2048px) {
            height: 1067px;
        }

        .inner-text {
            position: absolute;
            left: 48px;
            top: 60%;

            h2 {
                color: #FA444A;
                font-weight: 600;
                font-size: 40px;
                margin-bottom: 0;

                @media screen and (min-width: 1200px) and (max-width: 1535px) {
                    font-size: 36px;
                }

                @media screen and (min-width: 768px) and (max-width: 1028px) {
                    font-size: 24px;
                }
            }

            p {
                color: #FFFFFF;
                font-weight: 400;
                font-size: 30px;

                @media screen and (min-width: 1200px) and (max-width: 1535px) {
                    font-size: 24px;
                }

                @media screen and (min-width: 768px) and (max-width: 1028px) {
                    font-size: 18px;
                }
            }
        }

        .innertext-2 {
            h2 {
                font-weight: 400 !important;
                color: #fff !important;
            }

            p {
                color: #EC1C24 !important;
                font-weight: 600 !important;
            }
        }

        .innertext-3 {
            top: 55%;

            h2 {
                color: #EC1C24;
                font-weight: 600;
                font-size: 48px;

                @media screen and (min-width: 1200px) and (max-width: 1535px) {
                    font-size: 40px;
                }
            }

            h4 {
                font-weight: 400;
                font-size: 36px;
                color: #575757;

                @media screen and (min-width: 1200px) and (max-width: 1535px) {
                    font-size: 30px;
                }
            }
        }

        .innertext-new {
            color: #ffffff;

            @media screen and (min-width: 768px) and (max-width: 1024px) {
                margin-bottom: -100px !important;
            }

            h1 {
                font-size: 48px;
                font-weight: 400;
                margin-bottom: 24px;
            }

            button {
                background: #EC1C24;
                border-radius: 2px;
                color: #ffffff;
                border-color: transparent;
            }
        }

        .ipl-banner-button {
            position: absolute;
            left: 48px;
            top: 70%;

            @media screen and (min-width: 768px) and (max-width: 1024px) {
                margin-bottom: -100px !important;
            }

            button {
                background: #EC1C24;
                border-radius: 2px;
                color: #ffffff;
                border-color: transparent;
            }
        }

        // .ipl-banner-new-text {
        // color: #ffffff;
        // h1 {
        //     font-size: 48px;
        //     font-weight: 400;
        //     margin-bottom: 24px;
        // } 
        // }
    }
}

.chat-icon {
    position: fixed;
    width: 56px;
    height: 56px;
    bottom: 55px;
    right: 47px;
    background: #EC1C24;
    border-radius: 50px;
    text-align: center;
    z-index: 100;

    i {
        color: #fff;
        font-size: 1.6rem;
        margin-top: 16px;
    }
}

@media (max-width:767px) {
    .chat-icon {
        z-index: 5;
        width: 40px;
        height: 40px;
        bottom: 40px;
        right: 37px;

        i {
            font-size: 1.2rem;
            margin-top: 12px;
        }
    }

    /*.home_slider {
        height: 693px;
    }*/
    .parent {
        .swiper-pagination {
            margin-left: 25px;
            position: relative !important;
            margin-bottom: 0;
            padding-top: 2rem !important;
            margin: auto;

            .swiper-pagination-bullet {
                width: 20px;
                height: 4px;
            }
        }

        .hero-section {

            .inner-text {
                left: 2rem;
                top: 1.5rem;

                h2 {
                    font-size: 25px;
                }

                p {
                    font-size: 18px;
                }
            }

            .innertext-3 {
                width: 50%;

                h2 {
                    font-weight: 700;
                    font-size: 28px;
                    color: #F3F3F3
                }

                h4 {
                    font-size: 18px;
                    color: #F3F3F3;
                    font-weight: 400;
                    font-size: 20px;
                }
            }

            .innertext-2,
            .innertext-5 {
                h2 {
                    font-weight: 600;
                    font-size: 32px;
                }

                p {
                    font-size: 24px !important;
                    font-weight: 400;
                }
            }

            .innertext-6 {
                h2 {
                    font-weight: 600;
                    font-size: 32px;
                }

                p {
                    font-size: 24px !important;
                    font-weight: 400;
                }
            }

            .innertext-new {
                h1 {
                    display: none;
                }

                button {
                    margin-top: 100px;
                }
            }

            .ipl-banner-button {
                left: 1rem;
                top: 1.5rem;

                button {
                    margin-top: 40px;
                }
            }
        }
    }

    /*.hero-section-1{
        background-image: url('./ban-2.png') !important;
    }*/
    /*.hero-section-2{
        background-image: url('./bm-2.webp') !important;
    }*/
    /*.hero-section-2-mo{
        background-image: url('./mobile-superstar.webp') !important;
    }*/
    /*.hero-section-3{
        background-image: url('./bm-3.webp') !important;
    }*/
    /*.hero-section-4{
        background-image: url('./bm-4.webp') !important;
    }*/
    /*.hero-section-5{
        background-image: url('./bm-5.webp') !important;
    }*/
    /*.hero-section-6{
        background-image: url('./bm-6.jpg') !important;
    }*/
}