*{
    padding: 0;
    margin: 0;
}
body{
    overflow-x: hidden;
    overflow-y: scroll;
}
a{
    text-decoration: none;
    color: inherit;
    &:hover {
        color: inherit;
    }
}
button{
    background: transparent;
    border-radius: 2px !important;
}

.nav-change{
    *{
        color: #7E7E7E;
        font-size: 14px;
        padding-right: .5rem;
        
    }
    i{
        font-size: 12px !important;
    }
    span{
        color: #EC1C24;
    }
}
.to-hover{
    border-radius: 2px !important;
    padding: 1rem 0 !important;
    a{
        padding: 20px 2rem;
    }

}
.to-hover:hover{
    background: linear-gradient(92.7deg, #9F1891 -20.06%, #D21A3F 55.9%, #F01C0E 113.41%);
    border: 1px solid #EC1C24 !important;
    color: #FFFFFF !important;
    transition: all .1s ease-in-out ;
}
img, video{
    border-radius: 4px !important;
}

@media (max-width: 767px){
    .to-hover{
        padding: 14px 0 !important;
        a{
            padding: 16px 28px !important;
        }
    
    }
}